<template>
  <div class="form-stepper form-stepper-job">
    <v-form
      ref="form"
      v-model="valid"
      class="form pa-6"
    >
      <v-subheader class="pb-4 pl-0">
        <v-icon class="mr-2" size="16">mdi-domain</v-icon>
        {{ $t('JOB.COMPANY') }}
      </v-subheader>

      <v-row class="ma-0">
        <v-col cols="12" class="pa-0">
          <v-text-field
            dense
            v-model="company_title"
            :rules="[$rules.required, $rules.maxlength(255)]"
            :label="$t('INPUT.TITLE')"
            :placeholder="$t('INPUT.TITLE')"
            outlined
            required
          />
        </v-col>
      </v-row>

      <v-row class="ma-0">
        <v-col class="pa-0 pl-1 pb-4">
          <v-autocomplete
            prepend-inner-icon="mdi-shape"
            v-model="company_sector_id"
            :items="sectorItems"
            item-value="value"
            item-text="text"
            required
            hide-details
            :label="$t('JOB.FIELD_SECTOR')"
            :placeholder="$t('JOB.FIELD_SECTOR_PLACEHOLDER')"
            outlined
            dense
          />
        </v-col>
      </v-row>

      <v-row class="ma-0">
        <v-col cols="12" class="pa-0">
          <v-text-field
            dense
            v-model="company_url"
            :label="$t('INPUT.URL')"
            :placeholder="$t('INPUT.URL')"
            outlined
            required
          />
        </v-col>
      </v-row>

      <v-row class="ma-0">
        <v-col cols="3" class="pa-0">
          <v-img src="/img/empty-image.png" width="250px" height="250px"></v-img>
        </v-col>
        <v-col cols="9" class="pa-0 pl-3">
          <v-textarea
            dense
            height="250px"
            v-model="company_description"
            :rules="[$rules.required, $rules.maxlength(10000)]"
            :label="$t('INPUT.DESCRIPTION')"
            :placeholder="$t('INPUT.DESCRIPTION')"
            outlined
            required
          />
        </v-col>
      </v-row>

      <v-subheader class="pb-4 pl-0">
        <v-icon class="mr-2" size="16">mdi-card-account-phone-outline</v-icon>
        {{ $t('INPUT.CONTACT') }}
      </v-subheader>

      <v-row class="ma-0">
        <v-col cols="12" class="pa-0">
          <v-text-field
            dense
            v-model="contactName"
            :rules="[$rules.maxlength(255)]"
            :label="$t('JOB.CONTACT_NAME')"
            :placeholder="$t('JOB.CONTACT_NAME')"
            outlined
            required
          />
        </v-col>
      </v-row>

      <v-row class="ma-0">
        <v-col cols="12" class="pa-0">
          <v-text-field
            dense
            v-model="contactEmail"
            :label="$t('JOB.CONTACT_EMAIL')"
            :placeholder="$t('JOB.CONTACT_EMAIL')"
            outlined
            required
          />
        </v-col>
      </v-row>

    </v-form>

    <v-divider />

    <v-toolbar
      color="white"
      flat
      height="70px"
      class="form-footer"
    >
      <v-spacer />
      <v-btn
        color="primary custom"
        class="mr-4"
        rounded
        depressed
        :disabled="!valid && !loading"
        large
        @click="validate"
      >
        <strong>{{ $t('COMMON.CONTINUE') }}</strong>
        <v-icon class="ml-4">mdi-arrow-right</v-icon>
      </v-btn>
    </v-toolbar>
  </div>
</template>

<style>

.autocomplete-wrap .v-select__selections {
  flex-wrap: nowrap;
}
.autocomplete-wrap .v-select__selections .v-chip--select {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  width: 50%;
}

</style>

<script>
export default {
  name: 'FormJobStepCompany',
  data: (_this) => ({
    sectorItems: _this.$i18n.t('JOB.XTRAMILE_SECTORS_ITEMS').sort((a, b) => a.text.localeCompare(b.text)),
    loading: false,
    valid: false,
    company_title: null,
    company_url: null,
    company_sector_id: null,
    company_description: null,
    contactName: null,
    contactEmail: null,
  }),
  props: {
    values: {
      type: Object,
      default: () => ({})
    }
  },
  watch: {
    values (n) {
      this.init()
    },
    locationInput (n) {
      this.searchLocationItems(n)
    }
  },
  mounted () {
    this.init()
  },
  methods: {

    init () {
      Object.keys(this.values).forEach((key, i) => {
        if (typeof this.values[key] !== 'undefined' && typeof this[key] !== 'undefined') {
          this[key] = this.values[key]
        }
      })
    },

    validate () {
      if (this.$refs.form.validate()) {
        const data = {}
        if (this.company) data.company = this.company
        if (this.company_title) data.company_title = this.company_title
        if (this.company_url) data.company_url = this.company_url
        if (this.company_sector_id) data.company_sector_id = this.company_sector_id
        if (this.company_description) data.company_description = this.company_description
        if (this.contactName) data.contact_name = this.contactName
        if (this.contactEmail) data.contact_email = this.contactEmail
        this.$emit('childData', data)
        this.$emit('validation', true)
        this.$emit('next')
      } else {
        this.$emit('validation', false)
      }
    },
  },
}
</script>
